<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/联系我们.jpg" lazy width="100%" height="100%">
    <div class="contact_box">
      <h3>市场部</h3>
      <p> 电话：0755-26736182-668</p>
      <p> 邮箱：lijj@vstarcam.com</p>
      <p> 手机/微信：13827200377</p>

      <h3>联系地址</h3>
      <p> 公司总部：深圳市宝安区石岩街道黄峰岭工业大道江峡科技园F-G栋 (邮编: 518108)</p>
      <p>研发中心: 深圳市南山区桃源街道平山社区留仙大道4093号南山云谷创新产业园综合服务楼801-810</p>

      <h3>附近站台</h3>
      <p>黄峰岭工业区 公交: 791路</p>
      <p>山城工业区 公交: 316路; 325b线; 325路; 326路; 332路; 392路; 630路; 651路; 654路; 759路; 866路; b691路; m206路; m217路; m234路; m262路; m345路; m378路; m379路</p>

      <p><a href=" http://j.map.baidu.com/d59Sw" target="_blank">百度地图</a></p>
      <img class="img" src="../../../../public/images/官网底部文字对应的二级页面/联系我们/智驹地图.jpg" lazy >

    </div>

  </div>
</template>

<script>
export default {
  name: "联系我们"
}
</script>

<style scoped>
.contact_box{
  width: 80%;
  margin:auto;
}
.img{
  text-align: center;
  margin-left: 20%;
}
</style>